 import { Editor } from '@tinymce/tinymce-react';
 import React, { useRef,  useEffect, useState } from "react";
 import Spinner from "react-text-spinners";

//  import tinymce from 'tinymce/tinymce';
//  import 'tinymce/icons/default`
//  const apiEndpoint = "http://0.0.0.0:8000/titles";


 
 export default function TMCEditor( {onInitFunc} ) {
  // const [stats, setStats] = useState({});
  // const [load, setLoad] = useState(false);
  //  const editorRef = useRef(null);
   
  //  const log = () => {
  //   console.log("wow");
  //    if (editorRef.current) {
  //      console.log(editorRef.current.getContent({format: 'text'}));
  //    }
  //  };
//    const CallRestApi = async ({setApiResponse, stats, setLoad}) => {
//     const response = await fetch(apiEndpoint,{
//         method: 'POST',
//         headers: {
//           'Accept': 'application/json',
//           'Content-Type': 'application/json'
//         },
//         body: JSON.stringify({"story": editorRef.current.getContent({format: 'text'}) })
//     });
//     const resopnseJson = await response.json();
//     // .then(
//     //     result => setApiResponse(JSON.stringify(result.json()))
//     // );
//     // setApiResponse(JSON.stringify(resopnseJson, null, 1));
//     setApiResponse(resopnseJson);
//     setLoad(false);
//     console.log(resopnseJson);
//     //const jsonResponse = await response.json();
//     // console.log(JSON.stringify(jsonResponse));
//     //return JSON.stringify(jsonResponse);
// };


    // const onChange = event => {
    //     setStats(event.target.value);
    //     console.log(stats);
    // }  
   return (
     <>
       <Editor
        //  onInit={(evt, editor) => setEditorRef(editor)}
        onInit={(evt, editor) => onInitFunc(editor)}
        //  initValue="Write your story..."
         textareaName='storycontent'
         init={{
            // selector: "#story",
            // content_css: "writer",
            // content_style: ".tox-notifications-container {display: none !important;}",
          placeholder:"Write your story | thoughts | journal entry | diary entry...",

           height: 600,
        //    max_width: 5,
           menubar: false,
           skin: 'naked',
           icons: 'thin',
           inline: false,
           plugins: [
            //  'advlist autolink lists link image charmap print preview anchor',
            //  'searchreplace visualblocks code fullscreen',
             'wordcount'
           ],
           toolbar: 'undo redo | formatselect | ' +
           'bold italic',
        //    'alignright alignjustify | bullist numlist outdent indent | ' +
        //    'removeformat | help',
           content_style: 'body { font-family:Roboto,monospace; font-size:14px }',
        //    selector: 'textarea',
           branding: false
         }}
       />
       {/* <button class="button-16" onClick={log}>Submit</button> */}

       {/* <button class="button" onClick={() => {setLoad(true);CallRestApi({setApiResponse, stats, setLoad})}}>Submit</button> */}
 
     </>
   );
 }
