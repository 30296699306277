import './fonts.css';
import { ReactComponent as BeejIcon } from './assets/beej512.svg';
// import Nature from './assets/Nature.jsx';


export default function Header(){
    return(
        // <div class="container">
        <div class="header">
            <BeejIcon class="beejicon"/>
            <header style={{fontFamily: 'Rampart One', fontSize: 'xxx-large'}} class="beejtext">Beej
                {/* <p class="subtitle has-text-right is-size-6 my-0 py-0">becasue every word can be a beginning...</p> */}
            </header> 
            <div class="dummy"></div>

            {/* <div class="dummy"><Nature /></div> */}
        </div>
        // </div>
    )
}