import './App.css';
import './simple-grid.css';
// import './raster2.css';
import TMCEditor from './TMCEditor';
import TMCTitle from './TMCTitle';
import Header from './Header';
import Footer from './Footer';
import React, { useRef,  useEffect, useState } from "react";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import TitleSkeleton from './TitleSkeleton';
import TitleResults from './TitleResults';
import ImgLoader from './ImageSkeleton';
import { info } from "./userinfo.js";
import Toastify from 'toastify-js';
import "toastify-js/src/toastify.css";

// const apiEndpoint = "https://beejcpu.gentlepond-1b307b8d.eastus.azurecontainerapps.io/titles";
// const apiEndpoint = "https://gpubeejt5.eastus.azurecontainer.io/titles";
const apiEndpoint = "https://cpubeejt5.eastus.azurecontainer.io/titles";
// import Spinner from "react-text-spinners";
function App() {
  // console.log(JSON.stringify(info()));
  const storyEditorRef = useRef(null);
  const titleEditorRef = useRef(null);
  // const [apiResponse, setApiResponse] = useState([<>&nbsp;</>,<>&nbsp;</>,<>&nbsp;</>]);
  const [apiResponse, setApiResponse] = useState([]);
  const [loading, setLoading] = useState(true);


  function setTitleEditorRef (passedEditor) {
    titleEditorRef.current = passedEditor;
  };

  function setStoryEditorRef (passedEditor) {
    storyEditorRef.current = passedEditor;
  };
   const CallRestApi = async () => {
    const response = await fetch(apiEndpoint,{
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        // body: JSON.stringify({"title": titleEditorRef.current.getContent({format: 'text'}), "story": storyEditorRef.current.getContent({format: 'text'})})
        body: JSON.stringify({"story": storyEditorRef.current.getContent({format: 'text'})})

    });
    // {loading?  Toastify({
    //   text: "Getting titles from the server...",
    //   duration: 1000,
    //   // destination: "https://github.com/apvarun/toastify-js",
    //   // newWindow: true,
    //   gravity: "center",
    //   position: 'center',
    //   stopOnFocus: true,
    //   style: {
    //     background: "linear-gradient(to right, #00b09b, #96c93d)",
    //   }
    // }).showToast() : {}}
    const data = await response.json();
    setApiResponse(data);
    setLoading(false);
    console.log(data);

    // .then(
    //     result => {
    //       // console.log(result.json());
    //       setApiResponse(JSON.stringify(result.json()));
    //       console.log(apiResponse);
    //     }
    //     // result => setApiResponse(resopnseJson)
    // )
    // .then(response => setLoading(false));
    // setApiResponse(JSON.stringify(resopnseJson, null, 1));
    // setApiResponse(resopnseJson);
    // console.log(resopnseJson);
    //const jsonResponse = await response.json();
    // console.log(JSON.stringify(jsonResponse));
    //return JSON.stringify(jsonResponse);
};
const saveStory = () => {

  const story =  JSON.stringify({"story": storyEditorRef.current.getContent({format: 'text'})});
  navigator.clipboard.writeText(story);
  //show a toast
  console.log(story);
  Toastify({
    text: "Your story has been copied to the cclipboad, just CTRL+V in your favourite editor!",
    duration: 3000,
    // destination: "https://github.com/apvarun/toastify-js",
    // newWindow: true,
    gravity: "center",
    position: 'center',
    stopOnFocus: false,
    close: true,
    style: {
      background: "linear-gradient(to right, #00b09b, #96c93d)",
    }
  }).showToast();
}
//   const [isLoading, setIsLoading] = useState(true);
//   const [message, setMessage] = useState("");
//   useEffect(() => {
//     // this simulates the time it takes to make a web request
//     setTimeout(() => {
//         setMessage("hello world");
//         setIsLoading(false);
//     }, 1000);
// }, []);
const log = () => {
  console.log("wow");
  //  if (editorRef.current) {
    //  console.log(editorRef.current.getContent({format: 'text'}));
    //  console.log(editorRef.current.getContent({format: 'text'}));
console.log({"title": titleEditorRef.current.getContent({format: 'text'}), "story": storyEditorRef.current.getContent({format: 'text'})});
CallRestApi();
  //  }
 };
  return (
    <>
    <Header></Header>
    <div class="container">
      
        
     
    {/* </div>
    <div class="container"> */}
   
      <div class="row">
        <div class="col-6 center">
          {/* <form> */}
            {/* <div id="title"><TMCTitle onInitFunc={setTitleEditorRef}/></div> */}
            <div id="story"><TMCEditor onInitFunc={setStoryEditorRef} /></div>
            {/* <button class="button-green center" onClick={CallRestApi}>Submit</button>&#xA0;&#xA0;&#xA0;
            <button class="button-16 end" onClick={saveStory}>Save my Story</button> */}

            <button class="pushable-g center" onClick={CallRestApi}><span class="front-g">Submit</span></button>&#xA0;&#xA0;&#xA0;
            <button class="pushable-b end" onClick={saveStory}><span class="front-b">Save my Story</span></button>

          {/* </form> */}
        </div>
        <div style={{
    // resize: 'horizontal',
    overflow: 'hidden',
    
    height: 'auto',
  }} id="results" class="col-6 center">
        {/* <div>{isLoading ? <Spinner theme="matrix" /> : ( */}
        <h3 style={{marginTop: 0, paddingTop: 0}}>Suggested Titles</h3>
        {loading ? <TitleSkeleton /> : <TitleResults { ...{base_model: "T5", titles:[apiResponse.titles[0]]} } /> }
        {loading ? <TitleSkeleton /> : <TitleResults { ...{base_model: "T5", titles:[apiResponse.titles[1]]} } /> }
        {loading ? <TitleSkeleton /> : <TitleResults { ...{base_model: "T5", titles:[apiResponse.titles[2]]} } /> }
          <div id="genimage" class="imageholder">
            <h3>Generated Image(s)</h3>
            {/* <img src="testimg.webp" alt="placeholder image" width="350"/> */}
            {/* <div class="demo center"></div> */}
            <ImgLoader />
          {/* </div>) */}
          {/* } */}
        </div>
        </div>
      </div>
    {/* </div>   */}
      {/* <TinyEditorComponent /> */}
    {/* <div class="container"> */}
      <div class="row center">
        <Footer class="col-12"></Footer>
      </div>
    </div>
      
    </>
  );
}

export default App;
