const TitleResults = (apiResponse) => {
    return (
        
        // <p class="titleastags">{apiResponse.title}<span class="t5">{apiResponse.model}</span></p>
        <p class="titleastags">{apiResponse.titles[0]}<span class="t5">{apiResponse.base_model}</span></p>
        // <p class="titleastags">{apiResponse[2]}<span class="gpt3">GPT 3</span></p>
       
    )
}

export default TitleResults;