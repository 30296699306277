import React from "react"
import ContentLoader from "react-content-loader"

const TitleSkeleton = (props) => (
  <ContentLoader 
    speed={0}
    // width={800}
    height="auto"
    viewBox="0 0 800 50"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    width="100%"
    // preserveAspectRatio="xMidYMid meet"
    preserveAspectRatio="none"
    {...props} 
  >
    <rect x="0" y="0" rx="5" ry="5" width="700" height="30" /> 
    <rect x="590" y="10" rx="25" ry="50" width="100" height="30" /> 
  </ContentLoader>
)

export default TitleSkeleton

