import qrcode from "./assets/qr_28odRL4hK3bFeGI9AA.png"

export default function Footer(){
    return(
        <div class="footer">
        <p>Contact Me | Suggestions | Keep in touch!</p>
        <div class="row">    
            <div class="col-4 info">
                    <p>About</p>
                    <p>GitHub</p>
                    <p>Privacy Policy</p>
                    <p>Disclaimer</p>
            </div>
            <div class="col-4">
                
                <form action="https://send.pageclip.co/waEW3b6FTahXjElp90aATj98zpriTIsC" class="pageclip-form" method="post">
                    <p>
                    {/* <label for="name">Name: </label> */}
                    <input type="text" name="name" placeholder="Your Name" />
                    </p>
                    <p>
                    {/* <label for="name">Email: </label> */}
                    <input type="email" name="email" placeholder="hello@world.com" />
                    </p>
                    <p>
                    {/* <label for="name">Subject: </label> */}
                    <input type="text" name="subject" placeholder="Email subject"/>
                    </p>
                    {/* <label for="name">Your message: </label> */}
                    <textarea type="text" name="message" placeholder="I would like to tell you that..." rows='3'/>
                {/* <input type="text" onblur="if(this.value==''){this.style.background='transparent';}" onfocus="this.style.background='white'"></input> */}
            
                    <br></br>
                    <button class="pageclip-form__submit button-16" type="button">
                    <span>Send</span>
                    </button>
                </form>
            </div>
            {/* <p>Support</p> */}

            <div class="col-4 support">
                {/* <div><a href='https://ko-fi.com/D1D41SHIS' target='_blank'><img height='40' src='https://cdn.ko-fi.com/cdn/kofi4.png?v=2' alt='Buy Me a Coffee at ko-fi.com' /></a></div> */}
                <div><b>IBAN</b>: CH91 0023 0230 7995 68M1 L</div>
                <div><b>QR IBAN</b>: CH92 3000 5230 7995 68M1 L</div>
                <div class="donate"><a href="https://paypal.me/shantamraj" target="_blank"><img height='40' src="https://www.paypalobjects.com/webstatic/en_US/i/buttons/PP_logo_h_150x38.png" alt="PayPal" /></a></div>
                <div class="donate center">
                    <a href="https://donate.stripe.com/28odRL4hK3bFeGI9AA">
                    <svg xmlns="http://www.w3.org/2000/svg" height="2em" viewBox="0 0 576 512"><path d="M492.4 220.8c-8.9 0-18.7 6.7-18.7 22.7h36.7c0-16-9.3-22.7-18-22.7zM375 223.4c-8.2 0-13.3 2.9-17 7l.2 52.8c3.5 3.7 8.5 6.7 16.8 6.7 13.1 0 21.9-14.3 21.9-33.4 0-18.6-9-33.2-21.9-33.1zM528 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h480c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zM122.2 281.1c0 25.6-20.3 40.1-49.9 40.3-12.2 0-25.6-2.4-38.8-8.1v-33.9c12 6.4 27.1 11.3 38.9 11.3 7.9 0 13.6-2.1 13.6-8.7 0-17-54-10.6-54-49.9 0-25.2 19.2-40.2 48-40.2 11.8 0 23.5 1.8 35.3 6.5v33.4c-10.8-5.8-24.5-9.1-35.3-9.1-7.5 0-12.1 2.2-12.1 7.7 0 16 54.3 8.4 54.3 50.7zm68.8-56.6h-27V275c0 20.9 22.5 14.4 27 12.6v28.9c-4.7 2.6-13.3 4.7-24.9 4.7-21.1 0-36.9-15.5-36.9-36.5l.2-113.9 34.7-7.4v30.8H191zm74 2.4c-4.5-1.5-18.7-3.6-27.1 7.4v84.4h-35.5V194.2h30.7l2.2 10.5c8.3-15.3 24.9-12.2 29.6-10.5h.1zm44.1 91.8h-35.7V194.2h35.7zm0-142.9l-35.7 7.6v-28.9l35.7-7.6zm74.1 145.5c-12.4 0-20-5.3-25.1-9l-.1 40.2-35.5 7.5V194.2h31.3l1.8 8.8c4.9-4.5 13.9-11.1 27.8-11.1 24.9 0 48.4 22.5 48.4 63.8 0 45.1-23.2 65.5-48.6 65.6zm160.4-51.5h-69.5c1.6 16.6 13.8 21.5 27.6 21.5 14.1 0 25.2-3 34.9-7.9V312c-9.7 5.3-22.4 9.2-39.4 9.2-34.6 0-58.8-21.7-58.8-64.5 0-36.2 20.5-64.9 54.3-64.9 33.7 0 51.3 28.7 51.3 65.1 0 3.5-.3 10.9-.4 12.9z"/></svg>
                    {/* &#xA0;&#xA0;<svg height="1em" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M352 0c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9L370.7 96 201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L416 141.3l41.4 41.4c9.2 9.2 22.9 11.9 34.9 6.9s19.8-16.6 19.8-29.6V32c0-17.7-14.3-32-32-32H352zM80 32C35.8 32 0 67.8 0 112V432c0 44.2 35.8 80 80 80H400c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32V432c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16H192c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z"/></svg> */}
                    </a>
                    <br></br>
                    <img src= { qrcode } style={{ width:"150px" }}></img>
                </div>
                
            </div>
        </div>
        <div class="build">Built with <span class="red">&#10084;</span> by <a href="https://www.shantam.io">Shantam Raj</a></div>
        </div>
        
    )
}